/* eslint-disable consistent-return */
/* eslint-disable react-func/max-lines-per-function */
import dayjs from 'dayjs'
import {
  B2BRegistrationStep,
  EMAIL_RESEND_TIME,
  RegistrationErrors
} from '../vars'
import { Event as GAEvent } from '../../../components/GA'
import { amplitudeIdentifyRegistrationDate } from '../../../components/amplitude/identify'
import { authCarrot } from '../../../api'
import { navigate } from 'gatsby'
import { trackCodeWrong } from '../../../components/amplitude/auth/trackCodeWrong'
import { useB2BRegistrationContext } from './useB2BRegistrationContext'
import { useConfirmRegistrationMutation } from '../../RegistrationModals/query'
import { useEmailConfirmationMutation } from '../query'
import { useErrorModalContext } from '../../RegistrationModals/hooks/useErrorProvider'
import { useRegisterResult } from '../../../atoms/useRegisterResult'

export const usePhoneCodeStepSubmit = () => {
  const [secondStepRegisterMutation] = useConfirmRegistrationMutation()
  const [emailConfirmationMutation] = useEmailConfirmationMutation()
  const context = useB2BRegistrationContext()
  const errorContext = useErrorModalContext()
  const { setRegisterResult } = useRegisterResult()
  return async (values) => {
    try {
      const response = await secondStepRegisterMutation({
        variables: {
          input: {
            code: values.code,
            token: context.formData.token
          }
        }
      })

      if (response.data.confirmRegistration?.code) {
        const code = response.data.confirmRegistration.code
        if (code === RegistrationErrors.USER_EXISTS) {
          errorContext.onIdentityTakenError()
          return
        }
        if (code === RegistrationErrors.INCOMPLETE_SESSION_DATA) {
          errorContext.onServerError()
          return
        }
        if (code === RegistrationErrors.INCORRECT_CODE) {
          trackCodeWrong()
          return { code: response.data.confirmRegistration.message }
        }
        if (code === RegistrationErrors.NO_SLOT_AVAILABLE) {
          return { code: response.data.confirmRegistration.message }
        }
      }

      const isNeedToSkipOTPEmailStep =
        context.formData.needsManualVerification &&
        context.formData.needsManualVerification === true

      if (response.data.confirmRegistration.jwt) {
        localStorage.setItem('username', `"${context.formData.name}"`)
        setRegisterResult({
          jwt: response.data.confirmRegistration.jwt,
          role: response.data.confirmRegistration.role,
          id: response.data.confirmRegistration.id,
          hadAnyRelationships:
            response.data.confirmRegistration.hadAnyRelationships,
          newPsychologistId: response.data.confirmRegistration.newPsychologistId
        })

        if (isNeedToSkipOTPEmailStep) {
          navigate('/wizard')
        } else {
          await emailConfirmationMutation({
            variables: {
              input: {
                email: context.formData.email,
                promocodeName: context.formData.promocode
              }
            }
          })
          context.updateContext({
            step: B2BRegistrationStep.EMAIL_CODE,
            token: response.data.sendRegistrationRequest?.token,
            retryToken: response.data.sendRegistrationRequest?.token,
            datetime: response.data.sendRegistrationRequest?.datetime,
            // TODO: use date returned by emailConfirmationMutation
            retryAvailableAfter: dayjs()
              .add(EMAIL_RESEND_TIME, 'seconds')
              .toDate(),
            userId: response.data.confirmRegistration.id,
            canBeRequestAgain: false,
            userJwt: response.data.confirmRegistration.jwt,
            attempt: 1
          })
        }

        amplitudeIdentifyRegistrationDate()
        await authCarrot(response.data.confirmEmail.id)
        GAEvent.registrationOk('from_registration')
      }
    } catch (err) {
      errorContext.onServerError()
    }
  }
}
