/* eslint-disable react-func/max-lines-per-function */
import { B2BRegistrationStep } from '../vars'
import { Event as GAEvent } from '../../../components/GA'
import { RegistrationSource } from '../../../atoms/mappers/gqlEnums/registrationSourse'
import { SourceID } from '../../../atoms/mappers/wizard/enums/sourceId'
import { amplitudeIdentifyLeadId } from '../../../components/amplitude/identify'
import { getAnalyticsDataFromCookies } from '../../../atoms/useFormValues'
import { getUTM } from '../../../atoms/initialQueryParams'
import { omit } from 'rambda'
import { sendRegistrationRequestInputSchema } from '../schema'
import { useB2BRegistrationContext } from './useB2BRegistrationContext'
import { useCheckPromocode } from '../query'
import { useErrorModalContext } from '../../RegistrationModals/hooks/useErrorProvider'
import { useFormattedCatalogFiltersData } from '../../RegistrationModals/hooks/useFormattedCatalogFiltersData'
import { usePsyCardStorage } from '../../Information/usePsyCardStorage'
import { useSendRegistrationRequestMutation } from '../../RegistrationModals/query'

export const useIdentityStepSubmit = () => {
  const context = useB2BRegistrationContext()

  const [storedValue] = usePsyCardStorage()
  const formattedCatalogFiltersData =
    useFormattedCatalogFiltersData(storedValue)

  const errorContext = useErrorModalContext()
  const utm = getUTM()
  const [firstStepRegisterMutation] = useSendRegistrationRequestMutation()
  const [checkPromocode] = useCheckPromocode()
  // eslint-disable-next-line consistent-return
  return async (values) => {
    try {
      const { data } = await checkPromocode({
        variables: {
          input: {
            promocodeName: values.promocode,
            email: values.email
          }
        }
      })

      if (data?.checkPromocodeAffiliation?.message) {
        return { promocode: data.checkPromocodeAffiliation?.message }
      }

      const needsManualVerification =
        data?.checkPromocodeAffiliation?.needsManualVerification

      const input = {
        phone: values.tel,
        name: values.name,
        age: Number(values.age),
        email: values.email ?? context.email,
        userRegistrationPreferences: omit(
          ['age', 'name', 'sourceId', 'sessionDateTime'],
          formattedCatalogFiltersData
        ),
        registrationSource: RegistrationSource.Form,
        promocodeName: values.promocode,
        attempt: Number(context.formData.attempt ?? 0),
        analyticsData: {
          sourceId: formattedCatalogFiltersData.sourceId ?? SourceID.UC_LWQXL8,
          utmSource: utm.utm_source,
          utmCampaign: utm.utm_campaign,
          utmMedium: utm.utm_medium,
          utmTerm: utm.utm_term,
          utmContent: utm.utm_content,
          uid: utm.uid,
          gclid: utm.gclid,
          pointOfContact: 'wizard_subscription',
          cityadsClickId: utm.click_id,
          ...getAnalyticsDataFromCookies()
        }
      }
      try {
        await sendRegistrationRequestInputSchema.validate(input)
        GAEvent.submitLeadCreate()
      } catch (error) {
        return { tel: error.message }
      }
      const response = await firstStepRegisterMutation({
        variables: {
          input
        }
      })

      if (response.data.sendRegistrationRequest?.token) {
        amplitudeIdentifyLeadId(
          response.data.sendRegistrationRequest?.bitrixLeadId
        )
        context.updateContext({
          age: values.age,
          name: values.name,
          step: B2BRegistrationStep.PHONE_CODE,
          token: response.data.sendRegistrationRequest?.token,
          retryToken: response.data.sendRegistrationRequest?.token,
          datetime: response.data.sendRegistrationRequest?.datetime,
          retryAvailableAfter:
            response.data.sendRegistrationRequest?.retryAvailableAfter,
          canBeRequestAgain: false,
          tel: values.tel,
          email: values.email,
          promocode: values.promocode,
          needsManualVerification,
          attempt: 1
        })
        localStorage.setItem('userAge', values.age)
        return response.data.sendRegistrationRequest?.token
      } else {
        errorContext.onServerError()
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
      errorContext.onServerError()
    }
  }
}
