export const EMAIL_RESEND_TIME = 60

export const B2BRegistrationStep = {
  IDENTITY: 'IDENTITY',
  PHONE_CODE: 'PHONE_CODE',
  EMAIL_CODE: 'EMAIL_CODE'
}

export const B2BRegistrationStepsMap = {
  [B2BRegistrationStep.IDENTITY]: 1,
  [B2BRegistrationStep.PHONE_CODE]: 2,
  [B2BRegistrationStep.EMAIL_CODE]: 3
}

export const subscription = {
  errors: true,
  hasSubmitErrors: true,
  hasValidationErrors: true,
  invalid: true,
  pristine: true,
  submitting: true,
  values: true
}

export const RegistrationErrors = {
  USER_EXISTS: 'USER_EXISTS',
  INCORRECT_CODE: 'INCORRECT_CODE',
  NO_SLOT_AVAILABLE: 'NO_SLOT_AVAILABLE'
}

export const EmailConfirmationErrors = {
  COMPANY_EMAIL_DOMAIN_MISMATCH: 'COMPANY_EMAIL_DOMAIN_MISMATCH',
  INVALID_OTP: 'INVALID_OTP',
  DUPLICATE_EMAIL: 'DUPLICATE_EMAIL'
}
